<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="main-wrap">
			<div class="content-list">
				<div class="item" v-for="(item, index) in tableData" :key="index">
					<div class="number">
						<span class="label">工单：</span>
						<span class="value">{{ item.number }}</span>
					</div>
					<div class="content">
						从
						<span class="b">{{ item.startPlace }}</span>
						到
						<span class="b">{{ item.endPlace }}</span>
					</div>
					<div class="handle-row">
						<button type="button" v-if="item.status == 1" @click="receiveEvent">接收</button>
						<template v-else>
							<button type="button" @click="startEvent">开始</button>
							<button type="button" @click="navigationEvent">导航</button>
						</template>
					</div>
				</div>
			</div>

			<KcEmpty v-if="!tableData.length"></KcEmpty>
		</div>
	</van-pull-refresh>
</template>

<script>
export default {
	name: 'workOrderList',
	components: {},
	data() {
		return {
			isRefresh: false,

			tableData: [
				{
					number: '00214352',
					startPlace: '中山大学第一附属医院门诊楼东大门',
					endPlace: '门诊楼5楼内科1号手术室',
					status: 1
				},
				{
					number: '0013564',
					startPlace: '中山大学第一附属医院门诊楼西大门',
					endPlace: '门诊楼4楼内科5号手术室',
					status: 2
				}
			]
		};
	},
	created() {},
	mounted() {},
	methods: {
		onRefresh() {
			this.isRefresh = true;
			this.getList(() => {
				setTimeout(() => {
					this.isRefresh = false;
				}, 300);
			});
		},
		getList(callback) {
			// if (typeof callbak == 'function') callbak();
		},
		receiveEvent() {},
		startEvent() {
			this.$router.push('/transportManage/transport/workOrderDetail');
		},
		navigationEvent() {}
	}
};
</script>

<style lang="scss" scoped>
.main-wrap {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
	background-size: 100% 195px;
	background-repeat: no-repeat;
	.content-list {
		padding: 10px;
		.item {
			background: linear-gradient(180deg, #d1deff 0%, #ffffff 100%);
			box-shadow: 0px 1px 1px 0px rgba(100, 101, 102, 0.1);
			border-radius: 8px;
			padding: 8px 12px;
			&:not(:first-child) {
				margin-top: 10px;
			}
			.number {
				font-size: 14px;
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				.label {
					color: #666;
				}
				.value {
					color: #666;
					font-weight: 700;
				}
			}
			.content {
				font-size: 16px;
				color: #333;
				line-height: 24px;
				.b {
					font-weight: 700;
					color: #3e73fb;
				}
			}
			.handle-row {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-top: 16px;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: -8px;
					border-top: 1px solid #ddd;
				}
				.status {
					color: #3e73fb;
					font-size: 14px;
					font-weight: 700;
				}
				button {
					padding: 0px 16px;
					line-height: 24px;
					border-radius: 24px;
					border: 1px solid #3e73fb;
					color: #3e73fb;
					font-size: 14px;
					background: none;
					&:not(:last-child) {
						margin-right: 10px;
					}
				}
			}
		}
	}
}
</style>
